import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import type { MembershipAwarenessFormState } from '../../../../formContext';
import { useMembershipAwarenessFormFcm, useMembershipAwarenessFormState } from '../../../../formContext';

type PaymentTerm = MembershipAwarenessFormState['paymentTerm'];

type ChipData = {
	label: string;
	active: boolean;
	value: PaymentTerm;
	dataTestId: string;
};

type Result = ModelHookResult<
	{
		visible: boolean;
		paymentTerms: ChipData[];
	},
	{
		onClick: (term: PaymentTerm) => void;
	}
>;

export function useTermSelector(): Result {
	const [{ paymentTerm }, { setPaymentTerm }] = useMembershipAwarenessFormState();
	const { oneTimeContributionsEnabled, yearlyContributionsAmounts } = useMembershipAwarenessFormFcm();
	const { translate } = useI18n();
	const track = useTracking();

	const paymentTerms = useMemo(() => {
		const terms: ChipData[] = [];
		if (oneTimeContributionsEnabled) {
			terms.push({
				label: translate('corgi.membership.payment_form.terms.one_time'),
				active: paymentTerm === 'one-time',
				value: 'one-time',
				dataTestId: 'payment-terms-awareness-one-time',
			});
		}

		terms.push({
			label: translate('corgi.membership.payment_form.terms.monthly'),
			active: paymentTerm === 'monthly',
			value: 'monthly',
			dataTestId: 'payment-terms-awareness-monthly',
		});

		if (yearlyContributionsAmounts?.enabled) {
			terms.push({
				label: translate('corgi.membership.payment_form.terms.yearly'),
				active: paymentTerm === 'yearly',
				value: 'yearly',
				dataTestId: 'payment-terms-awareness-yearly',
			});
		}
		return terms;
	}, [oneTimeContributionsEnabled, paymentTerm, translate, yearlyContributionsAmounts]);

	const onClick = (term: PaymentTerm) => {
		setPaymentTerm(term);
		void track('membership_ask_payment_term_selected', { payment_term: term });
	};

	return {
		data: {
			visible: paymentTerms.length > 1,
			paymentTerms,
		},
		actions: {
			onClick,
		},
	};
}
