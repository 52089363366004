import { useI18n } from '@change-corgi/core/react/i18n';

import { useMembershipAwarenessFormData } from '../../../../formContext';
import type { Props } from '../../../../shared/types';

import type { Result as OriginalResult } from './index';
import { useAmountSelector } from './index';

type Result = ModelHookResult<
	OriginalResult['data'] & {
		otherAmountButton: string;
	},
	OriginalResult['actions']
>;

function upperCaseFirstChar(s: string) {
	return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
}

export function useAmountSelectorV2(props: Props): Result {
	const { currency } = useMembershipAwarenessFormData();
	const { data, actions } = useAmountSelector(props);
	const { translate, getCurrencySymbol } = useI18n();

	return {
		data: {
			...data,
			otherAmountButton: `${getCurrencySymbol(currency)}${translate('corgi.membership.awareness.other_amount_label')}`,
			buttonText: upperCaseFirstChar(translate('corgi.membership.onsite_placement.button_text')),
		},
		actions,
	};
}
