import { MembershipAwareness } from '../components/MembershipAwareness';
import { MembershipAwarenessV2 } from '../components/MembershipAwareness/V2';
import { useMembershipAwarenessFormFcm } from '../formContext';
import { useMembershipExists } from '../shared/hooks/useMembershipExists';
import type { Props } from '../shared/types';

export function MembershipAwarenessContainer(props: Props): React.JSX.Element | null {
	const { membershipEnabled } = useMembershipAwarenessFormFcm();
	const membershipExists = useMembershipExists();
	if (!membershipEnabled || membershipExists) return null;

	const { version } = props;
	if (version === 'v2' || version === 'v2home') {
		return <MembershipAwarenessV2 {...props} />;
	}
	return <MembershipAwareness {...props} />;
}
