import React from 'react';

import { Button } from '@change/tomato-core-ui';

import type { Props } from '../../shared/types';

import { useAmountSelectorV2 } from './hooks/useAmountSelector/V2';

export function AmountSelectorV2(props: Props): React.JSX.Element {
	const {
		data: { selectedAmount, buttonAmounts, buttonText, otherAmountButton },
		actions: { onClick },
	} = useAmountSelectorV2(props);

	const { version } = props;

	return (
		<div className="flex flex-col gap-[16px]">
			{version !== 'v2home' && (
				<>
					<div className="flex gap-[12px]">
						{buttonAmounts.slice(0, 3).map(({ amount, amountLocalized }, index) => {
							return (
								<Button
									key={amount}
									data-testid="membership-awareness-form-amount-button"
									onClick={() => {
										onClick({ amount, buttonName: `a${index + 1}` });
									}}
									className={`sm:max-md:grow md:min-w-[58px] ${amount === selectedAmount.amount ? 'bg-surface-neutral-pressed' : 'bg-surface-neutral'}`}
									variant="secondary"
								>
									{amountLocalized}
								</Button>
							);
						})}
					</div>
					<div className="flex gap-[12px]">
						<Button
							data-testid="membership-awareness-amount-button-third"
							onClick={() => {
								onClick({ amount: buttonAmounts[3].amount, buttonName: 'a4' });
							}}
							className={`sm:max-md:grow md:min-w-[58px] ${buttonAmounts[3].amount === selectedAmount.amount ? 'bg-surface-neutral-pressed' : 'bg-surface-neutral'}`}
							variant="secondary"
						>
							{buttonAmounts[3].amountLocalized}
						</Button>
						<Button
							data-testid="membership-awareness-amount-button-other"
							onClick={() => {
								onClick({ amount: 0, buttonName: 'other' });
							}}
							className="bg-surface-neutral sm:max-md:grow md:min-w-[58px]"
							variant="secondary"
						>
							{otherAmountButton}
						</Button>
					</div>
				</>
			)}
			<div className="flex">
				<Button
					data-testid={version === 'v2' ? 'membership-awareness-support-button' : undefined}
					data-qa={version === 'v2home' ? 'homepage-contribute-section-cta' : undefined}
					variant="primary"
					className="sm:max-md:grow md:grow-0"
					onClick={() => {
						onClick({ buttonName: 'contribute' });
					}}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	);
}
