import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
	ratio: '1/2' | '2/5';
	media: React.ReactNode;
}>;

// TODO: this is temporary until packages/tomato/packages/core-ui/src/components/HeroLayout/HeroLayout.tsx supports different ratios
export function HeroLayout({ ratio, media, children }: Props): React.JSX.Element {
	return (
		<div className="flex items-stretch overflow-hidden max-md:flex-col md:flex-row">
			<div className={`overflow-hidden ${ratio === '1/2' ? 'h-[220px] md:h-auto md:basis-1/2' : 'md:basis-2/5'}`}>
				{media}
			</div>
			<div className={`overflow-hidden ${ratio === '1/2' ? 'md:basis-1/2' : 'md:basis-3/5'}`}>{children}</div>
		</div>
	);
}
