import { Chip, Text } from '@change/tomato-core-ui';

import type { Props } from '../../shared/types';

import { useTermSelector } from './hooks/useTermSelector';

export function TermSelector(props: Props): React.JSX.Element | null {
	const {
		data: { visible, paymentTerms },
		actions: { onClick },
	} = useTermSelector();
	const { version } = props;
	if (version !== 'v2' || !visible) return null;

	return (
		<div className="mb-[24px]">
			<div className="inline-block">
				<div className="flex gap-[8px] rounded-full bg-surface-secondary-hovered p-[4px]">
					{paymentTerms.map(({ label, value, active, dataTestId }) => {
						return (
							<Chip
								key={value}
								onClick={() => {
									onClick(value);
								}}
								data-testid={dataTestId}
								active={active}
								forceInteractive
								sx={{ border: active ? 'unset' : 'none', padding: '8px 12px' }}
							>
								<Text
									fontWeight={active ? 'bold' : 'normal'}
									size="medium"
									variant="body"
									sx={{ color: active ? 'white' : 'black' }}
								>
									{label}
								</Text>
							</Chip>
						);
					})}
				</div>
			</div>
		</div>
	);
}
